import * as React from "react";
import styled, { keyframes } from "styled-components";

const circleBounceDelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1); 
  }
`;

const SpinnerWrapper = styled.div<{ size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  position: relative;
`;

const Child = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 18%;
    height: 18%;
    background-color: ${props => props.color};
    border-radius: 100%;
    animation: ${circleBounceDelay} 1.2s infinite ease-in-out both;
  }
`;

const Circle2 = styled(Child)`
  transform: rotate(30deg);
  &:before {
    animation-delay: -1.1s;
  }
`;

const Circle3 = styled(Child)`
  transform: rotate(60deg);
  &:before {
    animation-delay: -1s;
  }
`;

const Circle4 = styled(Child)`
  transform: rotate(90deg);
  &:before {
    animation-delay: -0.9s;
  }
`;

const Circle5 = styled(Child)`
  transform: rotate(120deg);
  &:before {
    animation-delay: -0.8s;
  }
`;

const Circle6 = styled(Child)`
  transform: rotate(150deg);
  &:before {
    animation-delay: -0.7s;
  }
`;

const Circle7 = styled(Child)`
  transform: rotate(180deg);
  &:before {
    animation-delay: -0.6s;
  }
`;

const Circle8 = styled(Child)`
  transform: rotate(210deg);
  &:before {
    animation-delay: -0.5s;
  }
`;

const Circle9 = styled(Child)`
  transform: rotate(240deg);
  &:before {
    animation-delay: -0.4s;
  }
`;

const Circle10 = styled(Child)`
  transform: rotate(270deg);
  &:before {
    animation-delay: -0.3s;
  }
`;

const Circle11 = styled(Child)`
  transform: rotate(300deg);
  &:before {
    animation-delay: -0.2s;
  }
`;

const Circle12 = styled(Child)`
  transform: rotate(330deg);
  &:before {
    animation-delay: -0.1s;
  }
`;

interface LoadingSpinnerProps {
  size?: number;
  color?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = 24, color = "#524d57" }) => {
  return (
    <SpinnerWrapper size={size}>
      <Child color={color} />
      <Circle2 color={color} />
      <Circle3 color={color} />
      <Circle4 color={color} />
      <Circle5 color={color} />
      <Circle6 color={color} />
      <Circle7 color={color} />
      <Circle8 color={color} />
      <Circle9 color={color} />
      <Circle10 color={color} />
      <Circle11 color={color} />
      <Circle12 color={color} />
    </SpinnerWrapper>
  );
};

export default LoadingSpinner;
